import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

function EmailSubscribe(props) {
  const [email, setEmail] = useState('');

  const {subscriptionId, referenceUrl} = props;

  const handleSubmit = (e) => {
    e.preventDefault();

    const infoFields = {};
    if (subscriptionId) infoFields.SUBID = subscriptionId;
    if (referenceUrl) infoFields.REFURL = referenceUrl;

    addToMailchimp(email, infoFields)
      .then((data) => {
        console.log('Returned from mailchimp:');
        console.log(data);
        alert(`${data.result}: ${data.msg}`);
      })
      .catch((error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        console.log(`Form error: ${error}`);
      });
  };

  const handleEmailChange = (event) => {
    console.log('handleEmailChange', event.currentTarget.value);
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-8">
          <input
            type="email"
            name="email"
            id="email"
            defaultValue=""
            placeholder="your@email.com"
            onChange={handleEmailChange}
          />
        </div>
        <div className="col-4">
          <button type="submit">Submit</button>
        </div>
      </div>
    </form>
  );
}

export default EmailSubscribe;
