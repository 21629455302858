import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import EmailSubscribe from './emailSubscribe';

import '../utils/normalize.css';
import '../utils/css/screen.css';

const SubscriptionBar = (props) => {
  const {
    prompt,
    subscriptionId,
    referenceUrl,
  } = props;

  return (
    <div className="bar-subscribe row">
      <div className="col" />
      <div className="bar-subscribe col-10">
        <div className="row">
          <div className="col prompt">
            {prompt}
          </div>
        </div>
        <EmailSubscribe subscriptionId={subscriptionId} referenceUrl={referenceUrl} />
      </div>
      <div className="col" />
    </div>
  );
};

SubscriptionBar.defaultProps = {
  prompt: '',
  subscriptionId: null,
  referenceUrl: 'N/A',
};

SubscriptionBar.propTypes = {
  prompt: PropTypes.string,
  referenceUrl: PropTypes.string,
  subscriptionId: PropTypes.string,
};

export default SubscriptionBar;
